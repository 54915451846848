import { graphql } from "gatsby"
import React from "react"
import {Helmet} from "react-helmet"

import Footer from "../components/footer"
import Header from "../components/header"

import {Segment } from "semantic-ui-react"

import "semantic-ui-less/semantic.less"

export default function MainLayout({ children }: any)  {

  return <div style={{
    display: "flex",
      flexDirection: "column",
      minHeight: "100vh"
  }}>
  <Helmet>
    <meta charSet="utf-8" />
    <title>Raik Bieniek</title>
    <link rel="canonical" href="https://www.bieniek-it.de" />
  </Helmet>
  <Header />
  <Segment basic style={{flex: 1}} >
    {children}
  </Segment>
  <Footer />
</div>
}

