import { Link } from "gatsby"
import React from "react"
import { Container, Grid, Icon, List, Menu, Segment, SemanticICONS } from "semantic-ui-react"

const SocialNetwork = (props: {
  icon: SemanticICONS,
  link: string
}) => <a href={props.link}>
  <Icon name={props.icon} size="big" link inverted/>
</a>

const ContactLink = (props: {
  children: any,
  icon: SemanticICONS,
  link?: string
}) => props.link ?
  <List.Item icon={props.icon} as="a" href={props.link} content={props.children} /> :
  <List.Item icon={props.icon} content={props.children} />


export default () => <Segment inverted vertical>
  <Container textAlign="center">
    <Grid columns={4} stackable divided>
      <Grid.Row>
        <Grid.Column textAlign="left">
          <List link inverted>
            <ContactLink icon="mail" link="mailto:mail@bieniek-it.de">mail@bieniek-it.de</ContactLink>
            <ContactLink icon="phone" link="tel:+4915678610689">+49 15678 610689</ContactLink>
          </List>
        </Grid.Column>
        <Grid.Column textAlign="left">
          <List>
            <ContactLink icon="address card">Raik Bieniek<br />Euckenstraße 8b<br/>81369 München</ContactLink>
          </List>
        </Grid.Column>

        <Grid.Column>
          <SocialNetwork icon="user" link="https://www.freelance.de/Freiberufler/162226" />
          <SocialNetwork icon="github" link="https://github.com/rbi" />
          <SocialNetwork icon="xing" link="https://www.xing.com/profile/Raik_Bieniek" />
          <SocialNetwork icon="flickr" link="https://www.flickr.com/photos/143960893@N08/" />
        </Grid.Column>
        <Grid.Column>
          <List link inverted>
            <List.Item as={Link} to="/imprint">Impressum</List.Item>
            <List.Item as={Link} to="/privacy-policy">Datenschutz</List.Item>
          </List>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Container>
</Segment>
