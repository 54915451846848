import { graphql } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import { Container, Header, Icon, Image} from "semantic-ui-react"
import MainLayout from "../components/main-layout"

export default ({data}: any) => {
  return <MainLayout>
    <Container text>
      <Image style={{display: "block", marginLeft: "auto", marginRight: "auto", marginBottom:"100px"}} as={Img} fixed={data.file.childImageSharp.fixed} alt="Portrait Raik Bieniek" circular />
      <h1>Full-Stack Entwickler aus Leidenschaft</h1>
      <p>
        Hallo, ich bin Raik.
        Ich bin ein leidenschaftlicher <b>Full-Stack Entwickler</b> aus München.
        Ich entwickle Software mit <b>Java, Typescript, Rust</b> und anderen Technologien.
      </p>
      <p>
        Suchen Sie Unterstützung für Ihr Projekt? Dann <a href="mailto:Raik Bieniek <mail@bieniek-it.de>">schreiben Sie mir</a>.
        Sie finden mich auch auf <a href="https://www.malt.de/profile/raikbieniek">Malt</a>, <a href="https://www.freelance.de/Freiberufler/162226">Freelance.de</a> und <a href="https://www.gulp.de/gulp2/home/profil/raik.bieniek">Gulp</a>.
      </p>
      <h1>Über mich</h1>
      <ul>
        <li><a href="Lebenslauf.pdf">Lebenslauf (deutsch)</a></li>
        <li><a href="Resume.pdf">Resume (english)</a></li>
      </ul>
    </Container>
  </MainLayout>
}

export const query = graphql`
  query {
    file(sourceInstanceName: {eq: "images"}, name: { eq: "raik.bieniek" }) {
      childImageSharp {
        fixed(height: 200) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
