import { Link } from "gatsby"
import React from "react"
import {Image, Menu, Segment } from "semantic-ui-react"

export default () =>  <Segment basic>
  <Menu stackable text>
    <Menu.Item header as={Link} to="/">
      <img src="/logo.svg" width="50px" style={{marginRight: "5px"}}/>
      <span>Raik Bieniek</span>
    </Menu.Item>
  </Menu>
</Segment>
